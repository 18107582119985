import {
  FETCH_PARTNER_CONFIG,
  FETCH_OIDC_PROVIDER,
} from 'constants/actionTypes';

export const fetchPartnerConfig = (
  { partnerKey },
  { onSuccess, onFailure },
) => ({
  type: FETCH_PARTNER_CONFIG,
  payload: { partnerKey },
  meta: { onSuccess, onFailure },
});

export const fetchOidcProvider = (
  { partnerId, emailDomain },
  { onSuccess },
) => ({
  type: FETCH_OIDC_PROVIDER,
  payload: { partnerId, emailDomain },
  meta: { onSuccess },
});
