import { push as redirectTo } from 'connected-react-router';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  FlexCenter,
  FlexColumn,
  FlexRow,
  Header,
  LoadingBars,
  Text,
  CheckSmall,
  Icon,
  Testimonial,
  COLORS,
} from '@summer/ui-components';

import EmailDomainLookupForm from 'components/pages/onboarding/CreateAccountV2/EmailDomainLookupForm';
import { Disclaimer } from 'components/pages/onboarding/shared';
import { Link } from 'components/shared/typography';
import { supportAddress } from 'constants/emailLinks';
import { TabletSize, MobileSize } from 'constants/styleguide';
import { usePlatform } from 'hooks/platform';
import { fetchPartnerConfig } from 'redux/actions/createAccount.actions';
import { isLoading } from 'redux/selectors/ui.selectors';
import { hexToRgba } from 'utils/common';

const CreateAccountV2 = ({
  dispatch,
  match,
  isLoadingPartnerConfig,
  windowWidth,
}) => {
  const headerRef = useRef(null);
  const { isMobile } = usePlatform();

  const [backgroundHeight, setBackgroundHeight] = useState();
  const [partnerConfig, setPartnerConfig] = useState({
    partnerId: null,
    partnerName: null,
    logoSrc: null,
  });

  const matchedPartner = match.params.partnerKey;

  const fetchPartnerConfigOnSuccess = response => {
    const { partnerId, partnerName, logoSrc } = response;

    setPartnerConfig({ partnerId, partnerName, logoSrc });
  };

  useEffect(() => {
    dispatch(
      fetchPartnerConfig(
        { partnerKey: matchedPartner },
        {
          onSuccess: fetchPartnerConfigOnSuccess,
          onFailure: () => {
            dispatch(redirectTo('/login'));
          },
        },
      ),
    );
  }, [dispatch, matchedPartner]);

  useEffect(
    function updateBackgroundHeight() {
      setBackgroundHeight(headerRef.current?.offsetTop);
    },
    [windowWidth],
  );

  const getHelpText = () => {
    return (
      <Text paragraph small color="secondary" center>
        Having trouble? Contact us at{' '}
        <Link href={`mailto:${supportAddress}`} inheritColor underline>
          {supportAddress}
        </Link>{' '}
        and we’ll get back to you.
      </Text>
    );
  };

  const InfoItem = ({ children }) => (
    <InfoItemContainer>
      <Check width={16} stroke={COLORS.jade} SvgComponent={CheckSmall} />
      <Text large color="dark" paragraph>
        {children}
      </Text>
    </InfoItemContainer>
  );

  const infoItemsList = [
    'Access expert support',
    'Compare savings plans',
    'Lower your payments',
    'Apply for a forgiveness plan',
    'Track your loans',
  ];

  return isLoadingPartnerConfig ? (
    <LoadingContainer>
      <LoadingBars color={COLORS.azure} />
    </LoadingContainer>
  ) : (
    <Container>
      <Background backgroundHeight={backgroundHeight} />
      {!!partnerConfig.logoSrc && (
        <LogoContainer>
          <LogoText>
            <Text large bold>
              in partnership with
            </Text>
          </LogoText>
          <Logo width={240} src={partnerConfig.logoSrc} />
        </LogoContainer>
      )}
      <Info isMobile={isMobile}>
        <InfoHeader>
          <Header>We&rsquo;ve partnered to help you save.</Header>
          <div ref={headerRef} />
        </InfoHeader>
        {!!partnerConfig.partnerName && (
          <Text large color="dark" bold paragraph>
            All {partnerConfig.partnerName} employees now have access to
            Summer&rsquo;s premium student debt tool and resources.
          </Text>
        )}
        <Spacer />
        <Text large color="dark" bold paragraph>
          Account benefits:
        </Text>
        <InfoItems>
          {infoItemsList.map((item, idx) => (
            <InfoItem key={idx}>{item}</InfoItem>
          ))}
        </InfoItems>
        <Testimonial
          image="/images/testimonials/shante.png"
          quote="&ldquo;Summer answered all of my questions right then and there. They told me about the pros and cons of loan options and specifically what plan I qualify for. They were extremely clear in their messaging and it didn&rsquo;t feel like a sales pitch.&rdquo;"
          name="&mdash; Shante H., Oregon"
        />
      </Info>
      <FormAndHelp>
        <FormContainer>
          {/* TODO: Add config to determine whether form is displayed based on user sign up method */}
          <EmailDomainLookupForm partnerConfig={partnerConfig} />
          <Help>{getHelpText()}</Help>
        </FormContainer>
        <DisclaimerContainer>
          <Disclaimer />
        </DisclaimerContainer>
      </FormAndHelp>
    </Container>
  );
};

const LoadingContainer = styled(FlexCenter)`
  height: 100%;
`;

const Container = styled(FlexRow)`
  margin-top: 76px;
  padding: 0 24px 24px;
  width: 100%;
  justify-content: center;
  flex: 1 0 auto;

  @media (max-width: ${TabletSize}) {
    margin-top: 54px;
    flex-direction: column;
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(${props => props.backgroundHeight}px + 30px);
  background-image: linear-gradient(
    to bottom,
    rgba(252, 254, 255, 0.53),
    ${hexToRgba(COLORS.azure, '0.05')}
  );
  z-index: -1;

  transition: height 1s;

  @media (max-width: ${TabletSize}) {
    height: calc(${props => props.backgroundHeight}px + 24px);
  }
`;

const LogoContainer = styled(FlexRow)`
  position: absolute;
  top: 8px;
  right: 30px;
  align-items: center;
  height: 80px;

  @media (max-width: ${TabletSize}) {
    right: 24px;
  }

  @media (max-width: ${MobileSize}) {
    right: 24px;
  }
`;

const LogoText = styled.div`
  @media (max-width: ${TabletSize}) {
    display: none;
  }
`;

const Logo = styled.img`
  margin-left: 20px;
  width: ${({ width }) => width}px;

  @media (max-width: ${TabletSize}) {
    width: ${({ width }) => width - 30}px;
  }

  @media (max-width: ${MobileSize}) {
    ${({ width }) => (width > 200 ? `width: ${width - 125}px;` : '')}
  }
`;

const Info = styled.div`
  max-width: 390px;
  width: 100%;
  margin-right: 88px;
  margin-left: auto;

  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    max-width: 560px;
    margin: 0 auto;

    ${({ isMobile }) =>
      !isMobile &&
      `border-bottom: solid 1px ${COLORS.grey};
    `}

    margin-bottom: 32px;
  }
`;

const Spacer = styled.div`
  height: 24px;
`;

const FormAndHelp = styled.div`
  max-width: 560px;
  width: 100%;
  margin-right: auto;
  flex: 0 1 auto;

  @media (max-width: ${TabletSize}) {
    flex: 1 0 auto;
    margin-left: auto;
  }
`;

const Help = styled.div`
  margin-top: 16px;
`;

const DisclaimerContainer = styled.div`
  margin: 16px 24px;
`;

const InfoHeader = styled.div`
  margin-bottom: 60px;

  @media (max-width: ${TabletSize}) {
    margin-bottom: 40px;
  }

  & > h1 {
    font-size: 40px;

    @media (max-width: ${TabletSize}) {
      font-size: 32px;
    }
  }
`;

const FormContainer = styled.div`
  max-width: 560px;
  border-radius: 3px;
  box-shadow: 0 2px 6px 0 ${COLORS.medDarkGrey};
  padding: 48px 64px;
  background-color: ${COLORS.white};

  @media (max-width: ${TabletSize}) {
    box-shadow: inherit;
    padding: 0;
  }
`;

const InfoItems = styled(FlexColumn)`
  margin-top: 10px;

  @media (max-width: ${TabletSize}) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const InfoItemContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  width: 100%;
`;

const Check = styled(Icon)`
  margin-right: 16px;
  line-height: 32px;
  height: 16px;
  max-width: 16px;
  width: 16px;
  display: inline;
  flex: 1 0 auto;
`;

const mapStateToProps = state => ({
  isLoadingPartnerConfig: isLoading(state, 'fetchPartnerConfig'),
});

export default withRouter(connect(mapStateToProps)(CreateAccountV2));
