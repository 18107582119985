import {
  FETCH_PARTNER_CONFIG,
  FETCH_OIDC_PROVIDER,
  NETWORK_SUCCESS,
  NETWORK_FAILURE,
} from 'constants/actionTypes';
import { apiRequest } from 'redux/actions/api.actions';
import handleSideEffects from 'redux/handleSideEffects';

const createAccountMiddleware = handleSideEffects({
  [FETCH_PARTNER_CONFIG]: (dispatch, { payload: { partnerKey }, meta }) => {
    dispatch(
      apiRequest({
        url: `/v1/create-account/partner/${partnerKey}/config`,
        method: 'GET',
        meta,
        fromAction: FETCH_PARTNER_CONFIG,
        label: 'fetchPartnerConfig',
      }),
    );
  },

  [FETCH_OIDC_PROVIDER]: (
    dispatch,
    { payload: { partnerId, emailDomain }, meta },
  ) => {
    dispatch(
      apiRequest({
        url: `/v1/create-account/partner/${partnerId}/oidc-provider?emailDomain=${emailDomain}`,
        method: 'GET',
        meta,
        fromAction: FETCH_OIDC_PROVIDER,
        label: 'fetchOidcProvider',
      }),
    );
  },

  [NETWORK_SUCCESS]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_PARTNER_CONFIG) {
      const { partnerId, partnerName, logoSrc } = payload.response;

      if (meta.onSuccess) {
        meta.onSuccess({ partnerId, partnerName, logoSrc });
      }
    }

    if (meta.fromAction === FETCH_OIDC_PROVIDER) {
      const { name, displayName, logoSrc } = payload.response;

      if (meta.onSuccess) {
        meta.onSuccess({ name, displayName, logoSrc });
      }
    }
  },

  [NETWORK_FAILURE]: (dispatch, { payload, meta }) => {
    if (meta.fromAction === FETCH_PARTNER_CONFIG) {
      if (payload.error.status === 404) {
        if (meta.onFailure) {
          meta.onFailure();
        }
      }
    }
  },
});

export default createAccountMiddleware;
