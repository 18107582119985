import { push as redirectTo } from 'connected-react-router';
import { Formik } from 'formik';
import { isNil } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';

import {
  Button,
  FlexRow,
  Header,
  Text,
  TextInput,
  Strong,
  COLORS,
} from '@summer/ui-components';

import OAuthBox from 'components/pages/auth/Login/OAuthBox';
import { AuthMechanism } from 'constants/user';
import { fetchOidcProvider } from 'redux/actions/createAccount.actions';
import { getErrorCode, isLoading } from 'redux/selectors/ui.selectors';
import { createAccountPartnerValidationSchema } from 'schemas/onboard';

const getClientErrorMessage = errorCode => {
  if (errorCode === 404) {
    return `Please enter a valid work email to proceed. If you think something's wrong or need assistance, please contact your HR representative for help.`;
  }
  return 'Something went wrong. Please try again or contact Summer support if the issue persists.';
};

const defaultInitialValues = {
  email: '',
};

const EmailDomainLookupForm = ({
  dispatch,
  partnerConfig,
  errorCode,
  isLoadingOidcProvider,
}) => {
  const [userOidcProvider, setUserOidcProvider] = useState(null);

  const fetchOidcProviderOnSuccess = response => {
    const { name, displayName, logoSrc } = response;

    setUserOidcProvider({ name, displayName, logoSrc });
  };

  const handleSubmit = values => {
    const emailDomain = values.email.split('@')[1];
    dispatch(
      fetchOidcProvider(
        { partnerId: partnerConfig.partnerId, emailDomain },
        { onSuccess: fetchOidcProviderOnSuccess },
      ),
    );
  };

  const renderForm = formik => {
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      isValid,
    } = formik;

    return (
      <form onSubmit={handleSubmit}>
        <FormHeader>
          <Header as="h2">Get Started</Header>
          <Text paragraph>Select the option that best applies to you</Text>
        </FormHeader>
        <>
          {!userOidcProvider ? (
            <>
              <FormContainer>
                <EmailBox>
                  <TextInput
                    label="Work Email"
                    name="email"
                    error={errors.email}
                    value={values.email}
                    touched={touched.email}
                    isSubmitting={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputMode="email"
                    autoComplete="email"
                  />
                </EmailBox>
                {!isNil(errorCode) && (
                  <ErrorText paragraph>
                    {getClientErrorMessage(errorCode)}
                  </ErrorText>
                )}
              </FormContainer>
              <ButtonContainer>
                <Button
                  disabled={!isValid}
                  isLoading={isLoadingOidcProvider}
                  type="submit"
                >
                  Verify your eligibility
                </Button>
              </ButtonContainer>
            </>
          ) : (
            <OAuthBox
              provider={AuthMechanism.oidc}
              oidcProvider={userOidcProvider}
            />
          )}
          <DividerContainer>
            <DividerText>OR</DividerText>
          </DividerContainer>
          <ButtonContainer>
            <Button
              secondary
              onClick={() => {
                dispatch(redirectTo('/login'));
              }}
            >
              Already have a Summer account? Sign in
            </Button>
          </ButtonContainer>
        </>
      </form>
    );
  };

  return (
    <Formik
      initialValues={defaultInitialValues}
      validationSchema={createAccountPartnerValidationSchema()}
      onSubmit={handleSubmit}
    >
      {renderForm}
    </Formik>
  );
};

const FormHeader = styled.div`
  margin-bottom: 20px;

  & > :first-child {
    margin-bottom: 4px;
  }
`;

const ButtonContainer = styled.div`
  text-align: center;
  & > * {
    margin-bottom: 16px;

    width: 100%;
  }
`;

const DividerContainer = styled(FlexRow)`
  width: 100%;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 16px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    background-color: ${COLORS.grey};
    width: calc(50% - 24px);
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

const DividerText = styled(Strong)`
  color: ${COLORS.darkGrey};
`;

const ErrorText = styled(Text)`
  width: 100%;
  font-size: 14px;
  color: ${COLORS.orange};
  margin: 8px 0;
  text-align: left;
`;

const EmailBox = styled.div`
  width: 100%;
  margin-bottom: 16px;
`;

const FormContainer = styled(FlexRow)`
  flex-wrap: wrap;
  max-width: 100%;
`;

const mapStateToProps = state => ({
  errorCode: getErrorCode(state, 'fetchOidcProvider'),
  isLoadingOidcProvider: isLoading(state, 'fetchOidcProvider'),
});

export default withRouter(connect(mapStateToProps)(EmailDomainLookupForm));
